import './App.css';

function App() {
    return (
        <div className="App">
            <div className="container">
                <header className={"top"}>
                    <p className={"company-name"}>
                        Amayn
                    </p>

                    <div className={"floating-bar"}>
                        Coming<br/>soon
                    </div>
                </header>

                <div className="content">
                    <div className="launching-in">
                        <h1 className={"header"}>Launching in</h1>
                        <h2 className={"subheader"}>SUMMER 2024</h2>
                    </div>

                    {/*<h3 className="coming-soon">*/}
                    {/*    Coming<br/>soon*/}
                    {/*</h3>*/}
                </div>
            </div>
        </div>
    );
}

export default App;
